.result {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom, #ffffff, rgba(243, 243, 243, 0.86) 58%, rgba(199, 199, 199, 0.34) 114%);
  &__bgc {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &__body {
    width: 82%;
    height: 60%;
    margin: 0.5rem auto 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain
    }
  }
  &__btn {
    width: 2.39rem;
    height: 0.5rem;
    background-color: #be0f34;
    font-family: PingFangSC;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    line-height: 0.5rem;
    margin: 7% auto 0;
  }
}