
.game {
  width: 100%;
  height: calc(100vh - 42px);
  color: #be0f34;
  background-color: #fff;
  position: absolute;
  z-index: 3;
  top: 0;
  &__wrapper {
    width: 68.5%;
    height: 0.88rem;
    margin: 0.14rem auto 0.3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.12rem;
    &__image {
      width: 100%;
      height: 0.88rem;
      position: absolute;
      top: 0;
      left: 0;
    }
    &__text {
      font-family: PingFangSC;
      font-size: 0.18rem;
      font-weight: bold;
      line-height: 1.44;
      color: #be0f34;
      
    }

  }
  &__map {
    display: flex;
    flex-wrap: wrap;
    height: 52%;
    padding: 0 0.3rem;
    justify-content: space-between;
  }
  &__item {
    width: 45%;
    height: 40%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.3rem;
    &__wrapper {
      width: 100%;
      height: 90%;

    }
    &__map {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &__label {
      font-size: 18px;
    }
    &__text {
      font-size: 0.16rem;
      font-weight: bold;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-around;
  }
  &__product {
    width: 25%;
    position: relative;
    &__image {
      width: 0.8rem;
      height: 1.08rem;
    }
  }
  &__confirm {
    width: 64%;
    height: 0.5rem;
    background-color: #be0f34;
    line-height: 0.5rem;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
  }
}
