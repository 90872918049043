.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .44);
  font-family: PingFangSC;
  z-index: 10;
  color: #000;
  &__inner {
    width: 259px;
    height: 302px;
    border: solid 1px #979797;
    border-radius: 10px;
    position: relative;
    background-image: linear-gradient(140deg, #eeeeee 1%, #ffffff 51%, #eeeeee);
    img {
      width: 100%;
      height: 100%;
    }
    &__body {
      position: absolute;
      width: 259px;
      height: 100%;
      top: 0;
      left: 0;
      box-sizing: border-box;
      padding: 0 48px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 99;
      font-family: Arial;
    }
    &__title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
    &__btn {
      width: 100%;
      border-radius: 5px;
      height: 50px;
      background-color: #000;
      color: #fff;
      line-height: 50px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      margin: 30px 0 50px;
    }
  }
}