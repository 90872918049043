.winner {
  width: 100%;
  height: calc(100vh - 42px);
  color: red;
  background-color: #fff;
  position: absolute;
  z-index: 3;
  top: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &__wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    left: 0;
  }
  &__block01 {
    width: 100%;
    padding: 0.3rem 0.54rem;
    font-family: PingFangSC;
    font-size: 0.52rem;
    font-weight: 600;
    line-height: 1.12;
    color: #be0f34;
    &__inner {
      position: relative;
      text-align: left;
    }
    &__text {
      width: 2.55rem;
    }
    &__banner {
      width: 1.2rem;
      height: 1.2rem;
      position: absolute;
      bottom: -0.4rem;
      right: 0.7rem;
      z-index: 2;
    }
  }
  &__block02 {
    display: flex;
    padding: 0 0.3rem;
    height: 0.7rem;
    justify-content: space-between;
    &__product {
      width: 25%;
      display: flex;
      justify-content: center;
      position: relative;
      img {
        width: 0.52rem;
        object-fit: cover;
        height: 100%;
      }
    }
    .product01 {
      img {
        width: 0.7rem;
        position: absolute;
        top: 0.04rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .product02 {
      img {
        width: 0.64rem;
        position: absolute;
        top: 0.12rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .product04 {
      img {
        width: 0.64rem;
        position: absolute;
        top: 0.08rem;
        left: 50%;
        transform: translateX(-50%);

      }
    }

  }
  &__block03 {
    flex: 1;
    width: 100%;
    position: relative;
    &__wave {
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     z-index: -1;
    }
    &__buy {
      padding-top: 0.8rem;
      width: 88%;
      height: 100%;
      margin: 0 auto;
      img {
        width: 100%;
        height: 1.4rem;
      }
    }
    &__text {
      width: 0.99rem;
      text-align: left;
      font-family: PingFangSC;
      font-size: 0.24rem;
      font-weight: bold;
      color: #be0f34;
      height: 0.9rem;
      padding-right: 0.3rem;
    }
  }
}
