.restart {
  width: 100%;
  height: calc(100vh - 42px);
  color: red;
  background-color: #fff;
  position: absolute;
  z-index: 4;
  top: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    left: 0;
  }
  &__block01 {
    width: 100%;
    height: 55%;
    padding: 0.6rem 0 0.8rem;
    font-family: PingFangSC;
    font-size: 0.52rem;
    font-weight: bold;
    line-height: 1.12;
    color: #be0f34;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    &__title {
      font-family: Gotham;
      font-size: 0.4rem;
      font-weight: bold;
      text-align: center;
      color: #be0f34;
    }

    img {
      margin-top: 0.48rem;
      width: 1.11rem;
      height: 1.11rem;
    }
  }

  &__block02 {
    width: 100%;
    height: 60%;
    flex: 1;
    position: relative;
    &__buy {
      width: 88%;
      height: 60%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      position: relative;
      align-items: center;
      img {
        width: 100%;
        height: 1.45rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
     
    }
    &__text {
      width: 0.99rem;
      text-align: left;
      font-family: PingFangSC;
      font-size: 0.24rem;
      font-weight: bold;
      color: #be0f34;
      height: 0.9rem;
      padding-right: 0.3rem;
    }
  }
}
