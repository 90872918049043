.tabbar {
  position: absolute;
  bottom: 0.28rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &__icon {
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      font-size: 0.1rem;
      line-height: 1.17;
      color: #be0f34;
    }
    &__back {
      width: 34%;
      position: absolute;
      height: 0.43rem;
      left: 0.15rem;
      background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/arrow-left.svg');
      background-position: 8% center;
      padding-left: 0.15rem;
      text-align: left;
      background-color: #ffffff80;
      padding: 0 0.26rem 0 0.25rem;
      box-sizing: border-box;
    }
    &__home {
      height: 0.4rem;
      width: 0.4rem;
      border-radius: 50%;
      background-size: 100% 100%;
      background-color: transparent;
      background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/home-bottom.svg');
    }
    &__next {
      width: 34%;
      position: absolute;
      height: 0.43rem;
      right: 0.15rem;
      background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/arrow-right.svg');
      background-position: 92% center;
      padding-right: 0.15rem;
      text-align: right;
      background-color: #ffffff59;
      padding: 0 0.26rem 0 0.25rem;
      box-sizing: border-box;
    }
  }
}
