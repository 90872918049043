.launch {
  width: 100%;
  height: calc(100vh - 42px);
  color: #be0f34;
  background-color: #fff;
  position: absolute;
  z-index: 2;
  top: 0;
  &__content {
    height: 70%;
  }

  &__image {
    width: 2.63rem;
    height: 0.88rem;
    margin: 0.14rem auto 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.11rem;
    img {
      width: 100%;
      height: 0.88rem;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__text {
    font-family: PingFangSC;
    font-size: 0.18rem;
    font-weight: bold;
    line-height: 1.44;
    color: #be0f34; 
  }
  &__map {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.2rem;
    height: 38%; 
    justify-content: space-between;
  }
  &__item {
    width: 45%;
    height: 100%;
    &__wrapper {
      width: 100%;
      height: 90%;
      display: flex;
      justify-content: flex-end;
      align-self: flex-end;
      position: relative;
    }
    .name {
      height: 70%;
    }
    &__map {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

    }
    &__image {
      width: 80%;
      height: 100%;
      object-fit: contain;
      
    }
    &__label {
      font-size: 0.16rem;
      font-weight: bold;
    }
    &__text {
      font-size: 0.14rem;
    }
  }
  .map2 {
    .launch__item:nth-child(2) {
      .launch__item__image {
        width: 70%;
        height: 75%;
      }
    }
  }

  &__count {
    width: 100%;
    height: 20%;
    font-size: 0.12rem;
    color:#000;
    border: 3px sold #be0f34;
    &__circle {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      margin: 0 auto 5px;
      position: relative;
      color: #be0f34;
      &__countdown {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
      }
      &__number {
        font-size: 18px;
        font-weight: bold;
      }
    
      .c-cricle {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 100%;
        transform-origin: center center;
        animation: circle 16s linear;
        .plane {
          width: 30px;
          height: 20px;
          position: absolute;
          top: -9px;
          left: 50%;
          z-index: 99;
          transform: translateX(-50%) rotate(-135deg);
        }
      }
    }
    &__number {
      font-size: 0.3rem;
      color: #be0f34;
      font-weight: bold;
    }
    img {
      height: 90%;
    }
  }
}
@keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.box{
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
 

}
.left_box,.right_box{
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}
.left_box{
  left: 0;
}
.right_box{
  right: 0;
}
.left_item,.right_item{
  width: 100%;
  height: 100%;
}
.left_item{
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  transform-origin: right center;
  animation: loading_left 16s linear;
  -webkit-border-top-left-radius: 0.8rem;
  -webkit-border-bottom-left-radius: 0.8rem;
  -webkit-transform-origin: right center;
  -webkit-animation: loading_left 16s linear;
  background-color: #be0f34;
}
.right_item{
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  transform-origin: left center;
  animation: loading_right 16s linear;
  -webkit-border-top-right-radius: 0.8rem;
  -webkit-border-bottom-right-radius: 0.8rem;
  -webkit-transform-origin: left center;
  -webkit-animation: loading_right 16s linear;
  background-color: #be0f34;
}
.mask{
  /* 遮住div多余的部分，呈现出线条的效果 */
  position: absolute;
  width: 0.74rem;
  height: 0.74rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 50%;
  background-color: #fff;
}
@keyframes loading_left{
    0%{
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    50%{
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    100%{
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }
}
@keyframes loading_right{
    0%{
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    50%{
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
    100%{
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
}
