.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  background-color: #be0f34;
  &__title {
    background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/clarins-logo.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 60px;
    width: 137px;
  }
}
