.rule {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 100%;
  z-index: 1;
  transform: rotate(-12deg);
  &__text {
    padding: 18px;
    &__title {
      font-size: 18px;
      font-weight: 600;
      color: #be0f34;
    }
    &__desc {
      padding-top: 20px;
      font-size: 12px;
      text-align: left;
      line-height: 1.5;
    }
  }
  &__container_map {
    position: relative;
    height: 196px;
    width: 185px;
    background-color: white;
    background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pattern.jpg');
    box-shadow: 0px 0px 5px 0px #00000070;
    &__cta {
      position: absolute;
      bottom: -49px;
      right: -30px;
      height: 80px;
      width: 80px;
      transform: rotate(12deg);
      border-radius: 50%;
      background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/cta.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  &__container_quizz {
    position: relative;
    height: 137px;
    width: 173px;
    background-color: white;
    background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pattern.jpg');
    box-shadow: 0px 0px 5px 0px #00000070;
    &__cta {
      position: absolute;
      bottom: -49px;
      right: -25px;
      height: 81px;
      width: 82px;
      transform: rotate(12deg);
      border-radius: 50%;
      background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/cta.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  &__container_sunrise {
    position: relative;
    height: 147px;
    width: 193px;
    background-color: white;
    background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pattern.jpg');
    box-shadow: 0px 0px 5px 0px #00000070;
    &__cta {
      position: absolute;
      bottom: -49px;
      right: -12px;
      height: 80px;
      width: 80px;
      transform: rotate(12deg);
      border-radius: 50%;
      background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/cta_sunrise.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
