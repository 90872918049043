.quiz {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  padding: 0 0.4rem;
  height: calc(100vh - 42px);
  box-sizing: border-box;
  background-image: linear-gradient(152deg, #eeeeee 1%, #ffffff 51%, #eeeeee);
  .bgc {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
  }
  &__title {
    width: 2.63rem;
    height: 0.93rem;
    margin: 0.14rem auto 0.28rem;
    position: relative;
    box-sizing: border-box;
    font-family: PingFangSC;
    font-size: 0.18rem;
    font-weight: bold;
    text-align: center;
    color: #be0f34;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: 2.63rem;
      height: 0.93rem;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  &__step {
    padding: 0 0.05rem;
    font-family: Gotham;
    font-size: 0.14rem;
    font-weight: bold;
    color: #be0f34;
    span:nth-child(2) {
      color: #999;
    }
    &__progress {
      margin: 0.08rem 0 0.16rem;
      width: 100%;
      height: 6px;
      display: flex;
      border-radius: 3px;
      border: solid 1px #c7c7c7;
      background-color: #eeeeee;
      &__item {
        width: 33.3%;
        height: 0.06rem;
        background-color:  #be0f34;
      }
      &__item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &__item:nth-child(4) {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
  &__choose {
    position: relative;
    padding: 0.26rem 0.28rem 0.15rem;
    box-sizing: border-box;
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    &__image01 {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-image: url("https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pattern.jpg");
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
    &__image02 {
      width: 96%;
      height: 100%;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -2;
      background-image: url("https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pattern.jpg");
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
    &__image03 {
      width: 92%;
      height: 100%;
      position: absolute;
      top: 20px;
      left: 50%;
      z-index: -3;
      transform: translateX(-50%);
      background-image: url("https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/pattern.jpg");
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
  .questions {
    display: flex;
    flex-direction: column;
    &__title {
      font-family: PingFangSC;
      font-size: 0.18rem;
      font-weight: bold;
      color: #be0f34;
      margin-bottom: 0.15rem;
      text-align: left;
    }
    &__form.form1 {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-top: 30%;
    }
    .icon {
      width: 0.4rem;
      height: 0.4rem;
    }
    &__form {
      flex: 1;
      font-family: PingFangSC;
      font-size: 0.14rem;
      font-weight: bold;
      color: #000000;

      .option {
        width: 100%;
        height: 0.43rem;
        padding: 0 0.06rem;
        box-sizing: border-box;
        border-radius: 6px;
        border: solid 1px #c7c7c7;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        margin-bottom: 0.11rem;
        .icon {
          width: 0.28rem;
          height: 0.28rem;
        }
        &__text {
          margin-left: 0.09rem;
        }
      }

      .q2 {
        height: 0.78rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .active {
      border-radius: 6px;
      border: solid 1px #be0f34;
      color: #be0f34;
      background-color: #edd9dd;
    }

  }

  &__next {
    width: 100%;
    height: 0.5rem;
    line-height: 0.5rem;
    text-align: center;
    font-family: PingFangSC;
    font-size: 0.12rem;
    font-weight: bold;
    background-color: #eee;
    color: #fff;
  }
  .active {
    background-color: #be0f34;
  }
}