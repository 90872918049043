.rendering {
  position: relative;
  height: calc(100% - 42px);
  width: 100%;
  background-image: url('https://31ten.oss-cn-shanghai.aliyuncs.com/clarins-sunrise/bg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .loading {
    position: absolute;
    top: -60px;
    left: -60px;
  }
  &__canvas {
    width: 100%;
    height: 100%;
    position: absolute!important;
    top: 0;
    overflow: hidden;
    touch-action: none;
  }

  &__header,
  &__body,
  &__footer {
    z-index: 5;
  }

  &__header {
    opacity: 1;
    transition: opacity 1s ease;
    &__out {
      opacity: 0;
    }
    &__cdf {
      height: 0.31rem;
      margin: 0 auto;
      margin-top: 0.30rem;
    }
    &__text1 {
      position: absolute;
      top: 0.68rem;
      right: 0rem;
      left: 0;
      font-size: 0.36rem;
      font-weight: 600;
      color: #be0f34;
      line-height: 1.11;
    }
    &__text2 {
      position: absolute;
      top: 33%;
      right: 0px;
      left: 0;
      font-weight: 600;
      font-size: 0.16rem;
      line-height: 1.6;
      color: #be0f34;
      line-height: 1.25;
    }
    &__plane {
      position: absolute;
      top: 17%;
      left: -15px;
      background-repeat: no-repeat;
      background-size: auto;
      width: 90vw;
    }
  }
  &__footer {
    &__icon {
      pointer-events: none;
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      img {
        height: 20px;
      }
    }
    &__text {
      pointer-events: none;
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      font-size: 12px;
      color: #be0f34;
    }
  }
}